//各メモの表示を行う


//初期状態メモヘッダーのみ表示

//ヘッダーから開閉イベントを受けたら詳細をアニメーションで展開する

import React ,{useState} from 'react';
import {useSelector,useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';


import {IconButton,Box, Button}  from '@material-ui/core';
import {Menu,MenuItem} from '@material-ui/core';

import {DoubleArrowOutlined} from '@material-ui/icons';
import ReplyTwoToneIcon from '@material-ui/icons/ReplyTwoTone';
import AddCircleOutlineTwoToneIcon from '@material-ui/icons/AddCircleOutlineTwoTone';
import CheckBoxTwoToneIcon from '@material-ui/icons/CheckBoxTwoTone';
import CheckBoxOutlineBlankTwoToneIcon from '@material-ui/icons/CheckBoxOutlineBlankTwoTone';
import StarTwoToneIcon from '@material-ui/icons/StarTwoTone';
import StarBorderTwoToneIcon from  '@material-ui/icons/StarBorderTwoTone';
import FileCopy from '@material-ui/icons/FileCopy';
import { Typography } from '@material-ui/core';
import TagFaces from '@material-ui/icons/TagFaces';

import  {getDateMessage} from  '../lib/UtilityLibrary';
import ExpandText from './parts/ExpandText';
import {TagChipList} from './parts/TagChip';
import {MAKE_NEWFOLLOW_MODAL,MAKE_NEW_REPLY_MODAL,SHOW_SNACK} from '../lib/ActionTypeString';
import { TEXT_LIMIT } from '../lib/ServiceConfig';
import {hasreadCheck,hasfavCheck,editMemo, deleteMemo} from '../actions';

import { useWindowDimensions } from '../lib/useWindowDimensions';

const useStyles = makeStyles((theme) => ({


    box11:{
        width:"85%",
        padding: "0.5em 1em",
        margin: "2em 0",
        color: "#5d627b",
        background: "white",
        borderTop: "solid 5px #5d627b",
        boxShadow: "0 3px 5px rgba(0, 0, 0, 0.22)",
        p:{
            margin: 0, 
            padding: 0    
        }
    },
    memo:{            
        width: '90%',
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1,2),
        borderTop: '2px solid #ccc',
        fontSize:'15px'
      },
    ulbutton:{
        display:"flex",
        listStyle:"none",
        justifyContent:"flex-end",  
    },
    
    topset:{
        display:"flex",
        justifyContent:"space-between"

    },
    menuButton:{
        spacing:'10px',
    },
    toName:{
        textAlign:"right",
    },
    todatePublish:{
        textAlign:"right",
    },
    toCheckers:{
        alignItems:"left"
    },
    toFooter:{
        margin:"0 0 0 auto"
    },
    mobileFooter:{
        display:"flex",
        justifyContent: "flex-start",
    },

    icons:{
        justifyContent: "flex-end",
    },

    headertext:{
        justifyContent: "flex-start",  
    },

    libutton:{
        margin:10
    },

    followarea:{
        marginLeft:"5%",
        border:'2px solid #888',
        width:"90%",
        fontSize:"12px"
    },

    editable:{
        border:"2px solid",
        borderColor:"#ffd700",
        borderRadius:"10px",
       
        focus:{
            outline:"none"
        }
    },
    deleted:{
        textAlign:"center",
        verticalAlign:"middle",
        display:"table-cell",
        height:"50px",
        borderRadius:"5px",
        backgroundColor:"#ddd",
        color:"#888",
        fontStyle:"italic"

    }

  }));



const MemoColumn=({data,read,fav,rep=false})=>{
    //console.log(props);
    const [anchorEl,setAnchorEl]=useState(null);
    const menuOpen=Boolean(anchorEl);

    const [editable,setEditable]=useState(false);
    const [bdelete,setDelete]=useState(false);
    const [editText,setEditText]=useState(data.strMainText);
    //const editform=useRef(data.strMainText.replace(/\n/g,'<br>'));
    
    const userId = useSelector(state => state.auth_login.userId,-1);
    const user_list = useSelector(state => state.auth_login.user_list,[]);

    const [expanded ,setExpanded]=useState(false);
    const [isFav, setFavorite] = useState(fav.includes(data.id),[]);
    const [hasRead, setRead] = useState(read.includes(data.id),[]);
    
    const history=useHistory();
    const classes=useStyles();
    const dispatch= useDispatch();

    
    const {width,height}=useWindowDimensions();
    const isMobile=width<500;

    const onFavClick=()=>{
        setFavorite(!isFav);
        dispatch(hasfavCheck(data.id,!isFav));
    };

    const onReadClick=()=>{
        setRead(!hasRead);
        dispatch(hasreadCheck(data.id,!hasRead));
    };

     const getNames=(arr)=>{
         //console.log("arr:", arr);
         const ars=String(arr).split(",");
         const names=ars.map((d)=>{
             if( String(userId) ===d ) return "自分";

            const tg=user_list.find((el)=>el.id===parseInt(d));
            //console.log("tg",tg);
            if (typeof(tg)=="undefined") return "不明";
            return tg.strName;
         });
         
         return names.reduce((acc,value)=>acc+","+value);
     };

    const copyTextToClipboard=(text)=> {
        navigator.clipboard.writeText(text)
        .then(()=> {
            dispatch({type:SHOW_SNACK,msg:"コピーしました"});
        }, (err)=> {
            
            dispatch({type:SHOW_SNACK,msg:"コピー失敗しました"});
        });
    };


     const MemoManipulate=()=>{
         const openFunc=()=>{
            setEditable(true);
            setAnchorEl(null);
         };
    
        const menuClick=(e)=>{
            setAnchorEl(e.currentTarget);            
        };

        const menuClose=()=>{
            setAnchorEl(null);
        };
        const startDelete=()=>{
            setDelete(true);
            setAnchorEl(null);
        };

         return(
             <span>
            <IconButton edge="start" color="inherit" onClick={menuClick} ><TagFaces/></IconButton>
            <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={menuClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            >
                <MenuItem onClick={()=>openFunc(data.strMainText)}>メモを修正</MenuItem>
                <MenuItem onClick={startDelete}>メモを削除</MenuItem>
            </Menu>
          </span>
         )
     }

    const EditableColumn=(str)=>{
        
        const checkEditable=(str)=>{
            setEditText(str);
        }
        const cancelEditable=()=>{
            setEditable(false);
        }

        const onEditEnd=()=>{
        
            const sendTxt=editText.replace(/<br>/g,'\n');
            dispatch(editMemo(data.id,sendTxt));
            setEditable(false);    
        }
    
        return(
            <Box >
                <div>
                <div><Typography variant='h6' >編集</Typography></div>
                <div className={classes.editable}>
                 <span  contentEditable dangerouslySetInnerHTML={{__html:data.strMainText.replace(/\n/g,'<br>')}} onInput={(e)=>checkEditable(e.target.innerHTML)} />
                 </div>
                </div>
                <div className={classes.toFooter}>
                    <span><Button color="primary" variant="contained" onClick={()=>onEditEnd()} ><Typography variant='button' >投稿</Typography></Button> </span>
                    <span><Button color="default" variant="contained" onClick={()=>cancelEditable()} ><Typography variant='button' >キャンセル</Typography></Button> </span>
                </div>
            
            </Box>
        )
     };



    const DeleteColumn=()=>{
        
        const cancelDelete=()=>{
            setDelete(false);
   
        }
        const onDeleteEnd=()=>{
            dispatch(deleteMemo(data.id));    
        }
    
        return(
            <Box >
                <div><Typography variant='h6' >このメモを削除する</Typography></div>
                <div className={classes.toFooter}>
                    <span><Button color="primary" variant="contained" onClick={()=>onDeleteEnd()} ><Typography variant='button' >削除</Typography></Button> </span>
                    <span><Button color="default" variant="contained" onClick={()=>cancelDelete()} ><Typography variant='button' >キャンセル</Typography></Button> </span>
                </div>
            
            </Box>
        )
     };

     const senderColumn=()=>{
            
        return (
            <span className={classes.toFooter}>
                <div className={classes.todatePublish}> { getDateMessage(data.datePublish) }</div>
                {  data.listReceiver & data.listReceiver!=="" ?
                    <div className={classes.toName} >宛先:{getNames(data.listReceiver)}</div>
                    :
                    <div/>
                }
            </span>
        )
     }



    return (
        <div>

                <Box className={classes.box11}>
                    <div className={
                        isMobile ?
                         null   
                        : classes.topset
                        } >
                        <div className={classes.headertext}>
                            <div><Typography variant='h6' >{getDateMessage(data.dateRegist) }</Typography></div>
                            <div><Typography variant='subtitle2' >{getNames(data.keySender)}より </Typography></div> 
                        </div>
                        <div className={classes.ulbutton}>
                            <div className={classes.ulbutton}>
                                <IconButton onClick={!hasRead ?  onReadClick : ()=>{} }>
                                    {hasRead ? (<CheckBoxTwoToneIcon/>  ):( <CheckBoxOutlineBlankTwoToneIcon/>)}
                                </IconButton>
                            </ div>
                            <div className={classes.icons} >
                                <IconButton onClick={onFavClick} >
                                    {isFav ? ( <StarTwoToneIcon/> ):( <StarBorderTwoToneIcon/> )}
                                </IconButton>
                            
                            </div>
                            {!data.boolHasDeleted ?
                                <div>
                                    <IconButton onClick={()=>copyTextToClipboard(data.strMainText)}>
                                        <FileCopy/>
                                    </IconButton>
                                </div>
                                :
                                <div/>
                            }

                            {userId===data.keySender & !data.boolHasDeleted ?
                                <div className={classes.icons} >
                                    {MemoManipulate()}
                                </div>
                                :
                                <div/>
                            }
                        </div>
                    </div>
                          
                    {!editable ?
                        <div>
                            <div><span > <TagChipList listStr={data.strTaglist} /></span></div>
                            { data.boolHasDeleted ?
                                <div className={classes.deleted}><Typography variant='h6' >このメモは削除されました</Typography></div>
                              :
                                <ExpandText txtSource={data.strMainText} limit={TEXT_LIMIT} />
                              
                            } 

                        </div>
                    :
                        EditableColumn(editText)
                    }

                    { data.keyFollowId &&
                        <div className={classes.followarea}>
                        < div><div>{getDateMessage(data.keyFollowId.dateRegist) }</div><div> <TagChipList listStr={data.keyFollowId.strTaglist} /> </div> <ExpandText txtSource={data.keyFollowId.strMainText} limit={TEXT_LIMIT}/></div>
                        </div>
                    }
                    {bdelete ?
                        DeleteColumn()
                    :
                        <div>
                            {isMobile ?
                                senderColumn()
                                :
                                <div/>
                            }
                        <div className={classes.mobileFooter} >
                            <div><IconButton edge="start" color="inherit"  className={classes.menuButton} onClick={()=>dispatch({type:MAKE_NEW_REPLY_MODAL,reply_source:data})} ><ReplyTwoToneIcon size="small"/></IconButton></div>
                            <div><IconButton edge="start" ize="small" color="inherit" onClick={()=>dispatch({type:MAKE_NEWFOLLOW_MODAL,follow_data:data})} ><AddCircleOutlineTwoToneIcon size="small"/></IconButton></div>
                            {data.keyReplyBase &  !rep ?
                                <div><Button edge="start" ize="small" color="inherit" onClick={()=>history.push("/reply/"+data.id)}   endIcon={<DoubleArrowOutlined />}> 応答を開く </Button></div>
                                :
                                null
                            }

                            {data.boolHasModified &&
                                <div> 修正あり </div>
                            }
                            {

                            }
                            {!isMobile ?
                                senderColumn()
                                :
                                <div/>
                            }
                        </div>
                        </div>
                    }
                </Box>
        </div>
    )

}

export default MemoColumn;