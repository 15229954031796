export const PUSH_SHOW_MEMO_BUTTON="PUSH_SHOW_MEMO_BUTTON";
export const PRIVATE ='PRIVATE';
export const TEAM ='TEAM';
export const CLOSE_MEMOMAKE='CLOSE_MEMOMAKE';
export const CLOSE_PASSWORD='CLOSE_PASSWORD';
export const OPEN_PASSWORD='OPEN_PASSWORD';
export const LOAD_NEW_MEMO="LOAD_NEW_MEMO";
export const RESET_MEMO="RESET_MEMO";
export const MEMO_END="MEMO_END";
export const ERROR_RESET="ERROR_RESET";
export const RESULT_ERROR="RESULT_ERROR";
export const CONNECT_ERROR="CONNECT_ERROR";
export const CRITICAL_ERROR ="CRITICAL_ERROR";

export const NO_ACTION="NO_ACTION";

export const USER_LOGIN="USER_LOGIN";
export const DONE_LOGOUT="DONE_LOGOUT";
export const RESET_AUTH="RESET_AUTH";

export const SHOW_REPLY="SHOW_REPLY";
export const TAGGED_START="TAGGED_START";
export const TAGGED_SEARCH="TAGGED_SEARCH";
export const MEMO_INSERT_END = "MEMO_INSERT_END";
export const MEMO_EDIT_END_MAIN ="MEMO_EDIT_END_MAIN";
export const MEMO_EDIT_END_TAG ="MEMO_EDIT_END_TAG";

export const TAGGED_SEARCH_CONTINUE="TAGGED_SEARCH_CONTINUE";
export const RESET_TAG="RESET_TAG";
export const SHOW_SNACK="SHOW_SNACK";
export const LOADING_START= "LOADING_START";
export const LOADING_END= "LOADING_END";


export const PUSH_NEW_MEMO="PUSH_NEW_MEMO";
export const PUSH_PASSWORD="PUSH_PASSWORD";
export const DELETE_MEMO="DELETE_MEMO";
export const SIGN_UP="SIGN_UP";
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const USER_CONFIG_LIST = "USER_CONFIG_LIST";
export const CHANGE_USER_CONFIG = "CHANGE_USER_CONFIG";
export const CHANGE_USER_CONFIG_SHOW = "CHANGE_USER_CONFIG_SHOW";

export const SHOW_TAG_ADMIN= "SHOW_TAG_ADMIN";
export const MAKE_TAG_ADMIN= "MAKE_TAG_ADMIN";
export const MODIFY_TAG_ADMIN= "MODIFY_TAG_ADMIN";

export const GET_MEMO_REQUEST="GET_MEMO_REQUEST";
export const GET_MEMO_SUCCESS="GET_MEMO_SUCCESS";
export const GET_MEMO_FAILURE="GET_MEMO_FAILURE";
export const GET_NOTICE_LIST="GET_NOTICE_LIST";
export const FREE_MODAL_OPEN="FREE_MODAL_OPEN";
export const FORMED_MODAL_OPEN="FORMED_MODAL_OPEN";
export const MAKE_NEWFOLLOW_MODAL="MAKE_NEWFOLLOW_MODAL";
export const DELETE_NEWFOLLOW_MODAL="DELETE_NEWFOLLOW_MODAL";

export const MAKE_NEW_REPLY_MODAL="MAKE_NEW_REPLY_MODAL";
export const DELETE_NEW_REPLY_MODAL="DELETE_NEW_REPLY_MODAL";


export const CHANGE_MAIN_VIEW="CHANGE_MAIN_VIEW";
export const CHANGE_TAG_VIEW="CHANGE_TAG_VIEW";
export const CHANGE_REPLY_VIEW="CHANGE_REPLY_VIEW";

export const SHOW_FAVORITE="SHOW_FAVORITE";



