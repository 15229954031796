const GlobalCSS=()=>{
    return(
        {
        funcColor:{
            color:"red",
          borderColor:"red"
        }, 
        staffColor:{
          color:"green",
          borderColor:"green"
        },
        guestColor:{
          color:"gold",
          borderColor:"gold"
        },
        placeColor:{
            color:"blue",
            borderColor:"blue"
        },
        actionColor:{
            color:"blue",
            borderColor:"blue"
        },
        funcColorb:{
            backgroundColor:"red",
            color:"white",
        }, 
        staffColorb:{
            backgroundColor:"green",
            color:"white"
        },
        guestColorb:{
            backgroundColor:"gold",
            color:"white"
        },
        placeColorb:{
            backgroundColor:"blue",
            color:"white"
        },
        actionColorb:{
            backgroundColor:"blue",
            color:"white"
        }
    
    
    }
    );
};

export default GlobalCSS;